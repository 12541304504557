/* eslint-disable implicit-arrow-linebreak */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

// UI lib component
import { Container } from 'react-grid-system';

// Local UI components
import PageHeader from '../PageHeader';
import PageFooter from '../PageFooter';
import SideBar from '../Sidebar';

// Style
import './index.scss';
import 'aos/dist/aos.css';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */
/**
 * PageLayout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
function PageLayout({ children, loader, location }) {
  /* ********************************** HOOKS ********************************* */
  const [showSideBar, setSideBar] = useState(false);
  const [showFooter, setShowFooter] = useState(false);

  const [showSolutionsSideMenu, setSolutionsSideMenu] = useState(false);
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  useEffect(() => {
    setShowFooter(true);
    if (location?.pathname.split('/')[1] === 'solutions') {
      setShowFooter(false);
    }
    if (location?.pathname.split('/')[1] === 'solutions') {
      setTimeout(() => {
        setShowFooter(true);
      }, 2000);
    }
  }, [loader, location?.pathname]);

  function getFooter() {
    if (showFooter) {
      return <PageFooter />;
    }

    return '';
  }

  /* ******************************** RENDERING ******************************* */
  return (
    <>
      <Container className="page-layout flex flex-column fluid" fluid>
        <PageHeader
          siteTitle={data.site.siteMetadata?.title || 'Title'}
          showSideBar={showSideBar}
          setSideBar={setSideBar}
          setSolutionsSideMenu={setSolutionsSideMenu}
        />
        <Container className="flex max-w fluid">
          <SideBar
            setSideBar={setSideBar}
            showSideBar={showSideBar}
            showSolutionsSideMenu={showSolutionsSideMenu}
            setSolutionsSideMenu={setSolutionsSideMenu}
          />
          <main id="main">{children}</main>
        </Container>
        {getFooter()}
      </Container>
    </>
  );
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  loader: PropTypes.bool,
  location: PropTypes.objectOf(PropTypes.object),
};

PageLayout.defaultProps = {
  location: undefined,
  loader: undefined,
};

export default PageLayout;
