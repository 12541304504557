/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import metaImage from '../../images/banner.jpg';

function Seo({ description, meta, title, image, keywords }) {
  const { language } = useI18next();
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
          }
        }
      }
    `,
  );

  const metaDescription =
    description?.length > 0 ? description : site.siteMetadata.description;
  const defaultTitle = title?.length > 0 ? title : site.siteMetadata?.title;
  const defaultImage = image?.length > 0 ? image : metaImage;
  const metaKeywords =
    keywords?.length > 0 ? keywords : site.siteMetadata?.keywords;

  return (
    <Helmet
      htmlAttributes={{
        lang: language,
      }}
      title={defaultTitle}
      image={defaultImage}
      titleTemplate={!title ? `%s | ${defaultTitle}` : title}
      meta={[
        {
          name: 'title',
          property: 'og:title',
          content: title,
        },
        {
          name: 'description',
          property: 'og:description',
          content: metaDescription,
        },
        {
          name: 'image',
          property: 'og:image',
          content: image,
        },
        {
          name: 'type',
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata?.author || '',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: metaKeywords,
        },
      ].concat(meta)}
    />
  );
}

Seo.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
  image: '',
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  keywords: PropTypes.string,
};

export default Seo;
