/* eslint-disable react/jsx-indent */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
// UI lib component
import { Container, Visible, useScreenClass } from 'react-grid-system';
import { Link, navigate } from 'gatsby';

// Local Links
// import sidebarLinks from '../local-links/sidebar';

// Assets
import { useI18next } from 'gatsby-plugin-react-i18next';
import LOGO from '../../images/logo.svg';
import MORE_ICON from '../../images/icons/more-icon.svg';
import LESS_ICON from '../../images/icons/less-icon.svg';
import MENU_ICON from '../../images/icons/menu-icon.svg';
import PREVIOUS_ICON from '../../images/index/icons/blue-arrow.svg';
import SEPERATOR_ICON from '../../images/icons/seperator-icon.svg';

// API
import { API } from '../API';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                 SideBar                                    */
/* -------------------------------------------------------------------------- */

function SideBar({
  setSideBar,
  showSideBar,
  setSolutionsSideMenu,
  showSolutionsSideMenu,
}) {
  /* ********************************** HOOKS ********************************* */
  const screenClass = useScreenClass();
  const IS_MOBILE = ['xs', 'sm', 'md'].includes(screenClass);

  const [getSingleSolutionLevel1, setSingleSolutionLevel1] = useState([]);
  const [getSingleSolutionLevel2, setSingleSolutionLevel2] = useState([]);

  const [showSolutionsList, setShowSolutionsList] = useState({
    solutionID: '',
    isOpened: false,
  });

  const [activeHover, setActiveHover] = useState({
    solutionID: '',
    isHovered: false,
  });
  const { language } = useI18next();
  useEffect(() => {
    axios
      .get(API(language === 'fr' ? 'solution_level_1' : 'solution_level_1_en'))
      .then(({ data: { data } }) => {
        setSingleSolutionLevel1(data);
      });
    axios
      .get(API(language === 'fr' ? 'solution_level_2' : 'solution_level_2_en'))
      .then(({ data: { data } }) => {
        setSingleSolutionLevel2(data);
      });
  }, [language]);

  /* ------------------------------- CONST ------------------------------------ */
  // eslint-disable-next-line operator-linebreak
  const currentpath =
    typeof window !== 'undefined' ? window.location.pathname : '';
  const checkCurrentPath = (solution, number) =>
    solution.slug === currentpath.split('/')[number];

  // localization
  const { t } = useTranslation();
  const { sidebarLinks } = t('sidebar', { returnObjects: true });

  const links = sidebarLinks.map(({ id, path, title }) => {
    return (
      <li key={id} className="single-link-container">
        <Container
          className="single-link"
          onMouseEnter={() => {
            if (id === 2 && !IS_MOBILE) {
              setSolutionsSideMenu(true);
            }
          }}
          onMouseLeave={() => {
            if (id === 2) {
              setSolutionsSideMenu(false);
            }
          }}
          onClick={() => {
            if (id === 2 && IS_MOBILE) {
              setSolutionsSideMenu(true);
            } else {
              navigate(path);
              setSideBar(false);
              if (showSolutionsSideMenu) {
                setSolutionsSideMenu(false);
              }
            }
          }}
        >
          <span id={currentpath === path ? 'active' : path}>{title}</span>
        </Container>
      </li>
    );
  });
  /* ***************************** RENDER HELPERS ***************************** */
  function getSubSolutions(solution) {
    if (
      // eslint-disable-next-line operator-linebreak
      showSolutionsList.isOpened &&
      solution.id === showSolutionsList.solutionID
    ) {
      return (
        <ul className="sub-solutions-list">
          {getSingleSolutionLevel2.map((solutionLevel2) => {
            if (solutionLevel2.level_1.data.id === solution.id) {
              return (
                <>
                  {/* {solutionLevel2.isvisible === 'value1' && ( */}
                  <Container
                    className="sub-single-solution-list flex items-center fluid"
                    onMouseEnter={() => {
                      setActiveHover({
                        solutionID: solutionLevel2.id,
                        isHovered: true,
                      });
                    }}
                    onMouseLeave={() => {
                      setActiveHover({
                        solutionID: '',
                        isHovered: false,
                      });
                    }}
                    fluid
                  >
                    {activeHover.isHovered &&
                      activeHover.solutionID === solutionLevel2.id && (
                        <img
                          className="link-indicator"
                          src={SEPERATOR_ICON}
                          alt="seperator"
                        />
                      )}
                    <Container
                      className="single-sub-single-solution-list fluid"
                      onClick={() => {
                        navigate(
                          `/solutions/${solution.slug}/${solutionLevel2.slug}`,
                        );
                        setShowSolutionsList({
                          solutionID: '',
                          isOpened: false,
                        });
                        setSideBar(false);
                        setSolutionsSideMenu(false);
                      }}
                    >
                      {solutionLevel2.title}
                    </Container>
                  </Container>
                  {/* )} */}
                </>
              );
            }
            return '';
          })}
        </ul>
      );
    }
    return '';
  }

  function getMoreLessIcon(solution) {
    if (showSolutionsList.isOpened) {
      <img src={MORE_ICON} alt="more" />;
    }
    if (
      // eslint-disable-next-line operator-linebreak
      showSolutionsList.solutionID === solution.id &&
      showSolutionsList.isOpened
    ) {
      return <img src={LESS_ICON} alt="less" />;
    }
    return <img src={MORE_ICON} alt="more" />;
  }

  function getCloseSolutionMenu() {
    if (IS_MOBILE) {
      return (
        <Container
          className="menu-icon fluid"
          onClick={() => {
            setShowSolutionsList({
              solutionID: '',
              isOpened: false,
            });
            setSolutionsSideMenu(false);
          }}
          fluid
        >
          <img src={MENU_ICON} alt="menu" />
        </Container>
      );
    }
    return '';
  }

  function getSolutionsMenu() {
    return (
      <Container
        className={`solutions-menu ${
          !IS_MOBILE && showSolutionsSideMenu && 'show-solutions-menu'
        } ${IS_MOBILE && 'solutions-menu-mobile'} ${
          !showSolutionsSideMenu && 'hide-solutions-menu'
        } fluid`}
        onMouseEnter={() => {
          setSolutionsSideMenu(true);
        }}
        onMouseLeave={() => {
          setSolutionsSideMenu(false);
        }}
      >
        {getCloseSolutionMenu()}

        {/* solution fleshe */}
        <div className="sub-menu-title flex items-center justify-between fluid">
          <h1 className="title">Solutions</h1>
          <Visible md sm xs>
            <Container
              className="img-container flex items-center justify-center fluid"
              fluid
              onClick={() => {
                setSideBar(showSideBar);
                if (showSideBar) {
                  setSolutionsSideMenu(false);
                }
              }}
            >
              <img src={PREVIOUS_ICON} alt="previous-icon" />
            </Container>
          </Visible>
        </div>
        <ul className="solutions-list">
          {getSingleSolutionLevel1.map((solution) => (
            <Container className="single-solution-list fluid" key={solution.id}>
              <div className="flex items-center justify-between">
                <Container
                  className={`solution-title ${
                    checkCurrentPath(solution, 2) && 'active'
                  } fluid`}
                  onClick={() => {
                    navigate(`/solutions/${solution.slug}`);
                    setSolutionsSideMenu(false);
                    if (IS_MOBILE) {
                      setSideBar(false);
                    }
                  }}
                >
                  {solution.title}
                </Container>
                <Container
                  className="handler-icon cursor-pointer line-height-0 fluid"
                  onClick={() => {
                    setShowSolutionsList({
                      solutionID: solution.id,
                      isOpened: !showSolutionsList.isOpened,
                    });
                  }}
                >
                  {getMoreLessIcon(solution)}
                </Container>
              </div>
              {getSubSolutions(solution)}
            </Container>
          ))}
          <li>
            <Link to="/prescription" className="assitance-title fluid">
              Prescription
            </Link>
          </li>
        </ul>
      </Container>
    );
  }
  /* ******************************** RENDERING ******************************* */
  return (
    <>
      <Container className={`side-bar ${showSideBar && 'show-sideBar'} fluid`}>
        <Link to="/" aria-label="Go home">
          <img
            className="flex justify-center mr-0"
            src={LOGO}
            alt="Lurox logo"
          />
        </Link>
        <Container className="List fluid">
          <ul className="List__Links">{links}</ul>
        </Container>
      </Container>
      {getSolutionsMenu()}
    </>
  );
}

SideBar.propTypes = {
  showSideBar: PropTypes.bool.isRequired,
  showSolutionsSideMenu: PropTypes.bool.isRequired,
  setSideBar: PropTypes.func.isRequired,
  setSolutionsSideMenu: PropTypes.func.isRequired,
};

export default SideBar;
