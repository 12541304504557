/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';

// UI lib components
import { Container } from 'react-grid-system';

// Assets
import FACEBOOK_ICON from '../../images/icons/facebook-icon.svg';
import LINKEDIN_ICON from '../../images/icons/linkedin-icon.svg';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function PageFooter() {
  /* ******************************** RENDERING ******************************* */
  return (
    <Container className="page-footer" fluid>
      <Container>
        <div className="first-row flex items-center justify-between">
          <p className="address">
            Immeuble Luxor, 5 eme étage
            <br />
            Centre urbain nord 1082 Tunis
          </p>
          <Container className="contact-container flex flex-column items-flex-end fluid">
            <Container className="social-media-icons flex fluid" fluid>
              <a
                href="https://www.facebook.com/Luxortechnologyandtrade"
                target="blank"
              >
                <img src={FACEBOOK_ICON} alt="facebook-icon" />
              </a>
              <a
                href="https://www.linkedin.com/company/luxor-technology-and-trade/"
                target="blank"
              >
                <img src={LINKEDIN_ICON} alt="linkedin-icon" />
              </a>
            </Container>
          </Container>
        </div>
        <div
          className="second-row flex items-center justify-between fluid"
          fluid
        >
          <p>{`© ${new Date().getFullYear()} - Luxor`}</p>
          <p>
            Developed by
            <a className="eyespot-link" href="https://www.eyespot.agency/">
              Eyespot
            </a>
          </p>
        </div>
      </Container>
    </Container>
  );
}

PageFooter.propTypes = {};

PageFooter.defaultProps = {};

export default PageFooter;
