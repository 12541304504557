/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import axios from 'axios';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useTranslation } from 'react-i18next';
// import { useI18next, Link, useTranslation } from 'gatsby-plugin-react-i18next';

// UI lib components
import { Container, Visible } from 'react-grid-system';

// Assets

import LOGO from '../../images/logo.svg';
import ERROR_404 from '../../images/404/search.svg';
import SEACRH_ICON from '../../images/icons/search-icon.svg';
import PHONE_ICON from '../../images/icons/phone-icon.svg';

import MENU_ICON from '../../images/icons/menu-icon.svg';
import RESPONSIVE_SEACRH_ICON from '../../images/icons/search-icon-responsive.svg';

import CLOSE_ICON from '../../images/icons/close-icon.svg';
// API
import { SEARCHABLE_API } from '../API';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function PageHeader({ setSideBar, showSideBar, setSolutionsSideMenu }) {
  /* ********************************** HOOKS ********************************* */
  const [openSearchInput, setSearchInputView] = useState(false);
  const [getSearchInput, setSearchInput] = useState('');
  const [getReferencesSearch, setReferencesSearch] = useState([]);
  const [getPartnersSearch, setPartnersSearch] = useState([]);
  const [getSolutionsSearch, setSolutionsSearch] = useState([]);

  /* ******************************** FUNCTIONS ******************************* */
  const onChangeInputSearch = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
    axios
      .get(SEARCHABLE_API('reference', e.target.value))
      .then(({ data: { data } }) => {
        setReferencesSearch(data);
      });
    axios
      .get(SEARCHABLE_API('partner', e.target.value))
      .then(({ data: { data } }) => {
        setPartnersSearch(data);
      });
    axios
      .get(SEARCHABLE_API('solution', e.target.value))
      .then(({ data: { data } }) => {
        setSolutionsSearch(data);
      });
  };

  /* ****************************** RENDER HELPERS **************************** */
  function getSearchResults(header, data, output) {
    if (getSearchInput && data.length > 0) {
      return (
        <>
          <p className="search-title">{header}</p>
          {data.map((result) => {
            function getSolutionLink() {
              if (result.level_3) {
                return `${result.level_1?.data.slug}/${result.level_2?.data.slug}/${result.level_3?.data.slug}`;
              }
              if (result.level_2) {
                return `${result.level_1?.data.slug}/${result.level_2?.data.slug}`;
              }
              return `${result.level_1?.data.slug}`;
            }
            if (header === 'Solutions') {
              return (
                <Link
                  onClick={() => {
                    setSearchInputView(false);
                    setSideBar(false);
                    setSolutionsSideMenu(false);
                    setSearchInput('');
                  }}
                  key={result.id}
                  to={`/solutions/${getSolutionLink()}`}
                >
                  {result[output]}
                </Link>
              );
            }
            if (header === 'Partenaires') {
              return (
                <a
                  href={result.website_full_link}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {result[output]}
                </a>
              );
            }
            if (header === 'References') {
              return (
                <Link
                  onClick={() => {
                    setSearchInputView(false);
                    setSideBar(false);
                    setSolutionsSideMenu(false);
                    setSearchInput('');
                    localStorage.setItem(
                      'singleReference',
                      JSON.stringify(result),
                    );
                  }}
                  to="/details-reference/"
                >
                  {result[output]}
                </Link>
              );
            }
            return <Link to="/">{result[output]}</Link>;
          })}
        </>
      );
    }
    return '';
  }

  function getSearchContainer() {
    return (
      <>
        <div
          className={`search-container ${
            (getSearchInput || openSearchInput) && 'search-container-opened'
          }`}
        >
          <input
            className="search-input"
            value={getSearchInput}
            onChange={onChangeInputSearch}
            placeholder="Votre recherche ici"
          />
          <Container className="flex items-center fluid" fluid>
            <button className="search-btn " type="button">
              RECHERCHER
            </button>
            <Container
              className="close-icon line-height-0 fluid"
              onClick={() => {
                setSearchInputView(false);
                setSearchInput('');
              }}
              fluid
            >
              <img src={CLOSE_ICON} alt="close" />
            </Container>
          </Container>
        </div>
        <Container
          className={`search-result ${
            openSearchInput && getSearchInput && 'search-result-opened'
          }`}
          fluid
        >
          {getSearchResults('Solutions', getSolutionsSearch, 'title')}
          {getSearchResults('References', getReferencesSearch, 'project_name')}
          {getSearchResults('Partenaires', getPartnersSearch, 'title')}
          {getSearchInput &&
            getReferencesSearch.length === 0 &&
            getPartnersSearch.length === 0 &&
            getSolutionsSearch.length === 0 && (
              <Container
                className="not-found-page flex flex-column items-center justify-center fluid"
                fluid
              >
                <img src={ERROR_404} alt="404" />
                <h4>aucun résultat ne correspond à votre recherche</h4>
                <Link to="/">
                  <button type="submit">Acceuil</button>
                </Link>
              </Container>
            )}
        </Container>
      </>
    );
  }

    // state

    const [isLanguageOpen, setIsLanguageOpen] = useState(false);

  const { language, changeLanguage } = useI18next();
  // select language :
  function SelectLanguage() {
    return (
      <button
        type="button"
        className="select-lang"
        onClick={() => setIsLanguageOpen(!isLanguageOpen)}
      >
        {language}
        {isLanguageOpen && (
          <div
            className={`select-lang-options ${isLanguageOpen ? 'active' : ''}`}
          >
            <button
              type="button"
              className="select-lang__button"
              onClick={() => changeLanguage('en')}
            >
              en
            </button>
            <button
              type="button"
              className="select-lang__button"
              onClick={() => changeLanguage('fr')}
            >
              fr
            </button>
          </div>
        )}
      </button>
    );
  }
  // LOCALIZATION
  const { t } = useTranslation();
  const { btn } = t('header', { returnObjects: true });

  /* ******************************** RENDERING ******************************* */
  return (
    <>
      <Visible lg xl xxl>
        <header className="page-header flex items-center justify-flex-end">
          <Container
            className="single-element flex items-center fluid"
            onClick={() => setSearchInputView(true)}
          >
            <img className="icon" src={SEACRH_ICON} alt="search-icon" />
            <p className="text">{btn}</p>
          </Container>
          <Visible lg xl xxl>
            <Container
              className="single-element flex items-center fluid"
              onClick={() => window.open('tel:+21671752300')}
            >
              <img className="icon" src={PHONE_ICON} alt="phone-icon" />
              <p className="text">+216 71 752 300</p>
            </Container>
          </Visible>
          <SelectLanguage />
        </header>
      </Visible>
      <Visible md xs sm>
        <div className="page-header-responsive flex items-center justify-between">
          <Link to="/">
            <img className="logo" src={LOGO} alt="logo" />
          </Link>
          <Container className="icons flex items-center fluid">
            <Container
              className="fluid"
              onClick={() => setSearchInputView(true)}
            >
              <img
                className="search-icon"
                src={RESPONSIVE_SEACRH_ICON}
                alt="search"
              />
            </Container>
            <Container
              onClick={() => {
                setSideBar(!showSideBar);
                if (showSideBar) {
                  setSolutionsSideMenu(false);
                }
              }}
            >
              <img className="menu-icon" src={MENU_ICON} alt="menu" />
            </Container>
          </Container>
        </div>
      </Visible>
      {getSearchContainer()}
    </>
  );
}

PageHeader.propTypes = {
  setSideBar: PropTypes.func.isRequired,
  setSolutionsSideMenu: PropTypes.func.isRequired,
  showSideBar: PropTypes.bool.isRequired,
};

export default PageHeader;
