/* eslint-disable */
export const API = (table) =>
  `https://admin.luxor.com.tn/api/1.1/tables/${table}/rows?access_token=doZlr3zfCrpKsUaIC1OYKzbplQhGNU6c`;

  export const APIen = (table) =>
  `https://admin.luxor.com.tn/api/1.1/tables/${table}/rows?access_token=doZlr3zfCrpKsUaIC1OYKzbplQhGNU6c`;


export const SEARCHABLE_API = (table, query) =>
  `https://admin.luxor.com.tn/api/1.1/tables/${table}/rows?access_token=doZlr3zfCrpKsUaIC1OYKzbplQhGNU6c&q=${query}`;
